/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        'common': {
            init: function () {
                // JavaScript to be fired on all pages

                // menudropdownaction = function() {
                //     if ($(window).width() < 1600) {} else {
                //         $('.divFilter').scrollToFixed({ marginTop: 15 });
                //     }
                // };

                // menudropdownaction();

                // $(window).resize(function() {
                //     menudropdownaction();
                // });
                $('#mixContainer').mixItUp();
                
                $('.unconfirmed-popover').popover();
                $('.deduction-popover').popover({html:true, 
                template: '<div class="popover popover-large" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3><div class="popover-content"></div></div>'});

                $('a[href*="#"]:not([href="#"])').click(function () {
                    if (location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
                        var target = $(this.hash);
                        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                        if (target.length) {
                            $('html, body').animate({
                                scrollTop: target.offset().top
                            }, 1000);
                            return false;
                        }
                    }
                });


                $(".side-menu-item").each(function () {
                    $(".side-menu-item").click(function () {
                        $(this).children('ul:first').toggleClass("competitions-list-two");

                    });
                });

                // Add smooth scrolling to links with the class b2-scroll
                $('.b2-scroll').on('click', function (event) {

                    // Make sure this.hash has a value before overriding default behavior
                    if (this.hash !== "") {
                        // Prevent default anchor click behavior
                        event.preventDefault();

                        // Store hash
                        var hash = this.hash;

                        // Using jQuery's animate() method to add smooth page scroll
                        // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
                        $('html, body').animate({
                            scrollTop: $(hash).offset().top
                        }, 800, function () {

                            // Add hash (#) to URL when done scrolling (default click behavior)
                            if (hash !== '#Top') {
                                window.location.hash = hash;
                            }
                        });
                    } // End if
                });

                // Scroll and resize functionality to add a reduced class for styling  of items on scroll
                $(window).scroll(function () {
                    var scrollPercentage = 100 * ($(this).scrollTop() / $('body').height());
                    var scrollNumber = ($(this).scrollTop());

                    if (scrollPercentage >= 7 && scrollPercentage <= 100) {
                        $('body').addClass('reduced');

                    } else {
                        $('body').removeClass('reduced');
                    }

                });

                if ($('.fixtures-row')) {
                    $('.fixture-list .fixture').on('click', function (e) {
                        e.preventDefault();
                        myTarget = $(this).attr('data-tgt');
                        $('.fixture-list .fixture').removeClass('fixture-active');
                        $(this).addClass('fixture-active');
                        $('.fixture-detail-container .fixture-detail').removeClass('in');
                        $('#fixture_' + myTarget).addClass('in');
                    });
                }

                $(".page_item_has_children").append('<span class="toggle-one"><span class="fa fa-chevron-down" aria-hidden="true"></span></span>');

                $(".parent-one").click(function () {
                    $(this).toggleClass("open");
                    $(this).find('.fixture-toggle-one').toggleClass("rotate");
                    $(this).children('.toggle-one').toggleClass("rotate");
                });
                
                $(".fixture-toggle-one").click(function () {
                    e.stopPropagation();
                    $(this).toggleClass("rotate");
                    $(this).children('.parent-one').toggleClass("open");
                });

                $(".toggle-one").click(function (e) {
                    e.stopPropagation();
                    $(this).parent().toggleClass("open");
                    $(this).toggleClass("rotate");
                });

                new MlPushMenu(document.getElementById('mp-menu'), document.getElementById('trigger'));

                $(".closeTab").click(function () {
                    $('#mp-pusher').trigger('click');
                    $(".closeTab > i").addClass("spinners");
                    setTimeout(function () {
                        $('.closeTab > i').removeClass('spinners');
                    }, 1000);
                });

                /* MATCH HEIGHT CLASSES ONLY */
                $('.match').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });
                $('.homePanel').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.newsGrid').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.newsTitle').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.newsText').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.homepageFeature').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.sponsorImages').matchHeight({
                    byRow: false,
                    property: 'height',
                    target: null,
                    remove: false
                });

                $('.teamMatch').matchHeight({
                    byRow: true,
                    property: 'height',
                    target: null,
                    remove: false
                });

                /* END */
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    $('.filter').on('click', function (e) {
        e.preventDefault();
        if ($(window).outerWidth() < 768) {
            $("html, body").animate({scrollTop: $('#mixFilters').offset().top - 35});
        }
    });

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.